import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Flex from './Flex'
import styles from './ContentFlex.module.css'
import classNames from 'classnames'

const ContentFlexStyled = styled(Flex)(() => ({}))

const ContentFlex = ({ children, ...rest }) => {
  const classes = classNames(styles.ContentFlex, rest.className)
  return (
    <ContentFlexStyled {...rest} className={classes}>
      {children}
    </ContentFlexStyled>
  )
}

ContentFlex.propTypes = {
  children: PropTypes.node
}

export default ContentFlex
