import React from 'react'
import { FormikCheckbox, P } from 'ui/bend'
import { Flex } from '../../elements'
import styled from 'styled-components'

const Wrapper = styled(Flex)`
  display: flex;
  align-items: center;
  text-align: unset;
  margin-bottom: ${props => props.mb || 0};
`

const MarketingDislaimer = ({ mb }) => {
  return (
    <Wrapper mb={mb}>
      <div>
        <FormikCheckbox label={null} name='consent_marketing_email' />
      </div>
      <P>
        I agree to receive marketing emails with information, special offers and
        promotions about Alo Moves. I may opt out by clicking unsubscribe at the
        bottom of any email.
      </P>
    </Wrapper>
  )
}

export default MarketingDislaimer
