import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import styles from './ContentBox.module.css'
import Box from './Box'
import { Scales } from '../themes'

const ContentBoxStyled = styled(Box)(() => ({
  width: '100%',
  paddingLeft: '0',
  paddingRight: '0',
  maxWidth: Scales.MAX_WIDTH
}))

const ContentBox = ({ children, ...rest }) => {
  return (
    <ContentBoxStyled {...rest} className={styles.ContentBox}>
      {children}
    </ContentBoxStyled>
  )
}

ContentBox.propTypes = {
  children: PropTypes.node
}

export default ContentBox
