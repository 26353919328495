import React, { cloneElement } from 'react'
import PropTypes from 'prop-types'
import style from './Alert.module.css'
import css from 'classnames'
import { useState } from 'react'

const Alert = ({ children, type, message }) => {
  const [isShow, setIsShow] = useState(true)

  const renderAlert = function () {
    return cloneElement(children)
  }

  const handleClose = (e) => {
    e.preventDefault()
    setIsShow(false)
  }

  return (
    <div className={css(style.alert, style[type], !isShow && style.hide)}>
      <span className={style.closebtn} onClick={handleClose}>
        &times;
      </span>
      {children ? renderAlert() : message}
    </div>
  )
}

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  children: PropTypes.node
}

export default Alert
