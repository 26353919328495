import React from 'react'
import PropTypes from 'prop-types'
import styles from './Button.module.css'
import Spinner from '../themes/animations/Spinner'
import classNames from 'classnames'

const Button = ({ variant, children, disabled, processing, ...rest }) => {
  const buttonVariant = styles[variant]

  const Tag = rest.type && rest.type === 'link' ? `a` : `button`

  const classes = classNames(styles.button, rest.className, buttonVariant)

  return (
    <Tag {...rest} disabled={disabled || processing} className={classes}>
      {processing ? <Spinner /> : children}
    </Tag>
  )
}

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Button
