import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Box from './Box'

import { Success } from 'images'

const Check = styled(Box)((props) => ({
  borderRadius: '50%',
  transition: 'all .25s ease-in-out',
  background: props.selected
    ? props.theme.colors.base
    : props.theme.colors.grey3,
  fill: props.selected ? props.theme.colors.primary : props.theme.colors.grey1,
  height: props.height || 'inherit',
  width: props.width || 'inherit'
}))

const CheckToggle = ({ selected, ...rest }) => (
  <Check selected={selected} {...rest}>
    <Success height='100%' width='100%' />
  </Check>
)

CheckToggle.propTypes = {
  selected: PropTypes.bool
}

export default CheckToggle
