import React from 'react'
import PropTypes from 'prop-types'
import styles from './Caption.module.css'
import classNames from 'classnames'

const Caption = ({ variant, children, ...rest }) => {
  const captionVariant = styles[variant]

  const classes = classNames(styles.default, rest.className, captionVariant)

  return <p className={classes}>{children}</p>
}

Caption.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Caption
