import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { CSSTransition } from 'react-transition-group'
import { Box } from '../elements'

export const FadeBox = styled(Box)`
  transition: opacity ${(props) => props.timeout || '250'}ms ease-in-out;
  opacity: 0;
  &.fade-appear {
    ${(props) => props.absolute && 'position: absolute;'}
    opacity: 0;
  }
  &.fade-enter {
    ${(props) => props.absolute && 'position: absolute;'}
    opacity: 0;
  }
  &.fade-enter-done {
    ${(props) => props.absolute && 'position: relative;'}
    opacity: 1;
  }
  &.fade-enter-active {
    opacity: 1;
  }
  &.fade-exit {
    ${(props) => props.absolute && 'position: absolute;'}
    opacity: 1;
  }
  &.fade-exit-active {
    ${(props) => props.absolute && 'position: absolute;'}
    opacity: 0;
  }
`

const Fade = ({
  absolute,
  duration = 250,
  inTrigger = true,
  children,
  Component = FadeBox,
  variant,
  ref,
  ...rest
}) => (
  <CSSTransition
    in={inTrigger}
    timeout={duration}
    classNames='fade'
    component={null}
    appear
    mountOnEnter
    unmountOnExit
  >
    <Component timeout={duration} absolute={absolute} {...rest}>
      {children}
    </Component>
  </CSSTransition>
)

Fade.propTypes = {
  absolute: PropTypes.bool,
  children: PropTypes.node.isRequired,
  duration: PropTypes.number,
  inTrigger: PropTypes.bool,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Fade
