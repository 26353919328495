import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from '../elements'
import { Caption, CTA } from '../typography'
import styled from 'styled-components'
import { LeftArrowIcon } from 'components_v2/Icons'

const CaptionContainer = styled(Caption)`
  margin-left: 0.625rem;
  font-family: Arquitecta;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 135%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
  color: ${(props) => `${props.theme.colors.grey5}`};
  }
`

const ParagraphContainer = styled(CTA)`
  margin-bottom: 1rem;
  font-family: Arquitecta;
  font-size: 32px;
  font-style: normal;
  font-weight: 950;
  line-height: 40px;
  letter-spacing: 2.24px;
  text-transform: uppercase;
  /* Media query for mobile screens */
  @media (max-width: 768px) {
    font-size: 24px;
  }
`

const SentenceContainer = styled(CTA)`
  margin-bottom: 1rem;
  font-family: 'proxima-nova';
  text-transform: inherit;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.352px;
`

const FlexContainer = styled(Flex)`
  align-items: start;
  flex-direction: column;
  text-align: start;
`

const StepContainer = styled(Flex)`
  flex-direction: row;
  margin-bottom: 1rem;
  margin-top: 1rem;
  align-items: center;
`

const StepHeaderPartner = ({ body, step, title, leftArrow = true, ...rest }) => (
  <FlexContainer {...rest}>
    {step && (
      <StepContainer>
        {leftArrow && <LeftArrowIcon color='#636363' />}
        <CaptionContainer id='stepHeader_caption'>{step}</CaptionContainer>
      </StepContainer>
    )}
    {title && (
      <ParagraphContainer id='stepHeader_title'>{title}</ParagraphContainer>
    )}
    {body && <SentenceContainer id='stepHeader_body'>{body}</SentenceContainer>}
  </FlexContainer>
)

StepHeaderPartner.propTypes = {
  body: PropTypes.node,
  step: PropTypes.node,
  title: PropTypes.node
}

export default StepHeaderPartner
