import React from 'react'
import PropTypes from 'prop-types'
import styles from './Header.module.css'
import classNames from 'classnames'

const Header = ({ variant, children, ...rest }) => {
  const buttonVariant = styles[variant]

  const classes = classNames(styles.default, rest.className, buttonVariant)

  return <div className={classes}>{children}</div>
}

Header.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Header
