import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './Flex.module.css'

const Flex = ({ children, variant, ...rest }) => {
  const FlexVariant = variant ? styles[variant] : styles.flex

  const classes = classNames(FlexVariant, rest.className)

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  )
}

Flex.propTypes = {
  children: PropTypes.any
}

export default Flex
