import React from 'react'
import classNames from 'classnames'
import styles from './Box.module.css'
import PropTypes from 'prop-types'

const Box = ({ variant, children, ...rest }) => {
  const boxVariant = styles[variant]
  const classes = classNames(styles.button, rest.className, boxVariant)

  return (
    <div {...rest} className={classes}>
      {children}
    </div>
  )
}

Box.propTypes = {
  children: PropTypes.any,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Box
