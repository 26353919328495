import React from 'react'
import PropTypes from 'prop-types'
import styles from './CTA.module.css'
import classNames from 'classnames'

const CTA = ({ variant, children, ...rest }) => {
  const ctaVariant = styles[variant]

  const classes = classNames(styles.default, rest.className, ctaVariant)

  return <p className={classes}>{children}</p>
}

CTA.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default CTA
