//
// Mobile: Full page background image with content bottom-aligned
// Desktop: Split view with image on left and content on right (center-aligned)
//
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Image from './Image'
import styles from './Landing.module.css'
import { formattedBreakpoints } from '../themes'

const ImageReplace = styled(Image)(() => ({
  objectFit: 'cover',
  height: '100%',
  [`@media screen and (min-width: ${formattedBreakpoints[1]}px)`]: {
    display: 'none'
  }
}))

const FlexCenter = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flex: '1 auto'
}))

const FlexMid = styled('div')(() => ({
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex'
}))

const Landing = ({ image, children }) => {
  return (
    <div
      className={styles.landing}
      style={{ 'background-image': `url(${image})` }}
    >
      <div className={styles.columnImage}>
        <ImageReplace src={image} />
      </div>
      <FlexCenter>
        <FlexMid>{children}</FlexMid>
      </FlexCenter>
    </div>
  )
}

Landing.propTypes = {
  image: PropTypes.string.isRequired,
  border: PropTypes.bool,
  children: PropTypes.node
}

export default Landing
