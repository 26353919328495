import React from 'react'
import PropTypes from 'prop-types'

import Flex from './Flex'
import { componentHeights as heights } from 'ui/chandra/themes/scales'
import styled from 'styled-components'

const FlexFull = ({ children, scrollDesktop, ...rest }) => {
  const containerHeight = (format) => {
    return `calc(100vh - ${heights.navbar[format]})`
  }

  const height = scrollDesktop ? [] : containerHeight('desktop')

  const FlexReplace = styled(Flex)(() => ({
    height: height,
    minHeight: containerHeight('desktop')
  }))

  return <FlexReplace {...rest}>{children}</FlexReplace>
}

FlexFull.propTypes = {
  children: PropTypes.node,
  scrollDesktop: PropTypes.bool
}

FlexFull.defaultProps = {
  scrollDesktop: true
}

export default FlexFull
