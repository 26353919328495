import styled from 'styled-components'
import styles from './Image.module.css'
import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

const Image = ({ src, ...rest }) => {
  const classes = classNames(styles.image, rest.className)

  const Img = styled.div`
    grid-area: ${(props) => props.area || ''};
    object-fit: ${(props) => props.objectFit};
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  `

  return <Img className={classes} style={{ backgroundImage: `url(${src})` }} />
}

Image.propTypes = {
  src: PropTypes.string
}

export default Image
