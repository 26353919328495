import React from 'react'
import PropTypes from 'prop-types'

import { Flex } from '../elements'
import { Caption, CTA } from '../typography'
import styled from 'styled-components'

const CaptionContainer = styled(Caption)`
  font-weight: bold;

  @media all and (max-width: 1024px) {
    padding-bottom: null;
  }

  @media all and (max-width: 1023px) {
    padding-bottom: 8px;
  }
`

const ParagraphContainer = styled(CTA)`
  padding-bottom: 6px;
  font-size: 30px;
  font-weight: 950;
  line-height: 31.2px;
  @media all and (max-width: 1024px) {
    padding-bottom: null;
  }

  @media all and (max-width: 1023px) {
    padding-bottom: 9px;
    font-size: 28px;
  }
`

const SentenceContainer = styled(CTA)`
  font-size: 18px;
  font-family: 'proxima-nova';
  text-transform: inherit;
  margin-bottom: ${(props) => (props.$signUpPage ? '16px' : '60px')};

  @media all and (max-width: 1024px) {
    font-size: 14px;
    text-transform: inherit;
  }

  @media all and (max-width: 1023px) {
    font-size: 16px;
    text-transform: inherit;
  }

  @media all and (max-width: 640px) {
    margin-bottom: 5px;
    font-size: 15px;
    text-transform: inherit;
  }
`

const FlexContainer = styled(Flex)`
  align-items: start;
  flex-direction: column;
  text-align: start;
`

const StepHeader = ({ body, step, title, ...rest }) => (
  <FlexContainer {...rest}>
    {step && (
      <CaptionContainer id='stepHeader_caption'>{step}</CaptionContainer>
    )}
    {title && (
      <ParagraphContainer id='stepHeader_title'>{title}</ParagraphContainer>
    )}
    {body && rest.isSignUpPage && (
      <SentenceContainer $signUpPage id='stepHeader_body'>
        {body}
      </SentenceContainer>
    )}
    {body && !rest.isSignUpPage && (
      <SentenceContainer id='stepHeader_body'>{body}</SentenceContainer>
    )}
  </FlexContainer>
)

StepHeader.propTypes = {
  body: PropTypes.node,
  step: PropTypes.node,
  title: PropTypes.node
}

export default StepHeader
