import React from 'react'
import PropTypes from 'prop-types'
import styles from './Headline.module.css'
import classNames from 'classnames'

const Headline = ({ variant, children, ...rest }) => {
  const headlineVariant = styles[variant]

  const classes = classNames(styles.default, rest.className, headlineVariant)

  return <h1 className={classes}>{children}</h1>
}

Headline.propTypes = {
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default Headline
